<!-- This copmponent is responsible for adding individual datasets to the Data panel 
    - emit 
        - openPicker - open a modal containing the available datasets to open
        - addToDataGrids - add the selected dataset to the Data panel
-->
<template>
    <div>
        <b-modal
            @hide="cleanSearchBox()"
            size="lg"
            title="Datasets"
            ref="modal-datasetPicker"
            id="modal-datasetPicker"
            centered
            hide-footer
            ><div class="modal-container">
                <div class="search-container" role="search">
                    <div class="search-box-container">
                        <div class="search-box">
                            <img
                                src="../../assets/search-outline.svg"
                                class="search-img"
                                alt="Search"
                            />
                            <div class="search-input-container">
                                <input
                                    ref="searchBox"
                                    type="text"
                                    v-model="searchText"
                                    @click="searchinputenter(searchText)"
                                    @input="searchinputenter(searchText)"
                                    name="dataset-search-input"
                                    placeholder="Search for datasets"
                                    class="search-input"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="datasets-container">
                    <ejs-grid
                        height="500"
                        :dataSource="dataGrids"
                        ref="datasetsGrid"
                        :rowSelected="dataSetRowSelected"
                        :pageSettings="options"
                        :dataBound="dataBound"
                        :allowSorting="true"
                        :sortSettings="sortOptions"
                    >
                        <e-columns>
                            <e-column
                                field="DatasetAlias"
                                headerText="Dataset name"
                                width="150"
                            >
                            </e-column>
                            <e-column
                                field="DatasetDesc"
                                headerText="Description"
                                width="200"
                            >
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { datasetService } from '../../services/dataset.service';
import { GridPlugin, Sort } from '@syncfusion/ej2-vue-grids';
import Vue from 'vue';
Vue.use(GridPlugin);

export default {
    name: 'datasetpicker',
    data() {
        return {
            options: { pageSize: 50 },
            dataGrids: [],
            projectIds: '',
            datasetId: null,
            datasetName: '',
            selectedDatasets: [],
            searchText: '',
            sortOptions: {
                columns: [{ field: 'DatasetAlias', direction: 'Ascending' }],
            },
        };
    },
    provide: {
        grid: [Sort],
    },
    async created() {
        this.projectIds = this.ProjectID;
        await this.getToolsData(this.projectIds);

        if (this.getSelectedDatasets(this.projectIds).length !== 0) {
            this.processSelectedDatasets();
        } else {
            this.$emit('openPicker');
        }
    },
    methods: {
        cleanSearchBox() {
            this.searchText = '';
        },
        ...mapActions('gridFilters', {
            addSelectedDatasets: 'addSelectedDatasets',
        }),
        dataBound() {
            this.$refs.datasetsGrid.$el.ej2_instances[0].hideScroll();
        },
        searchinputenter(searchText) {
            const datasetsGrid = this.$refs.datasetsGrid.$el.ej2_instances[0];
            datasetsGrid.searchModule.search(searchText);
        },
        getToolsData(ids) {
            return new Promise((resolve) => {
                datasetService
                    .getTableselectools(ids)
                    .then((res) => {
                        let dataGrids = res.data;
                        let groupedTables = {};
                        if (dataGrids) {
                            this.dataGrids = [];
                            for (let i = 0; i < dataGrids.length; i++) {
                                for (let j = 0; j < dataGrids[i].length; j++) {
                                    this.dataGrids.push({
                                        DataSourceId:
                                            dataGrids[i][j].DataSourceId,
                                        DatasetId: dataGrids[i][j].DatasetId,
                                        DatasetType:
                                            dataGrids[i][j].DatasetType,
                                        DatasetAlias: dataGrids[i][j]
                                            .DisplayName
                                            ? dataGrids[i][j].DisplayName
                                            : dataGrids[i][j].DatasetAlias,
                                        DatasetName:
                                            dataGrids[i][j].DatasetName,
                                        DatasetDesc:
                                            dataGrids[i][j].DatasetDesc,
                                        DateFilterColumn:
                                            dataGrids[i][j].DateFilterColumn,
                                        CreationDate:
                                            dataGrids[i][j].CreationDate,
                                        ModifiedDate:
                                            dataGrids[i][j].ModifiedDate,
                                        name:
                                            dataGrids[i][j].DisplayName ||
                                            dataGrids[i][j].DatasetAlias,
                                        Type: dataGrids[i][j].Type,
                                        LegacyProjectIds:
                                            dataGrids[i][j].LegacyProjectIds,
                                        Tools: dataGrids[i][j].Tools,
                                        CanExportPDF: dataGrids[i][j].PdfExport,
                                        DataExportType:
                                            dataGrids[i][j].DatasetAlias,
                                    });
                                    if (
                                        dataGrids[i][j].DatasetName ==
                                        'sp_ExportSamples'
                                    ) {
                                        groupedTables[
                                            dataGrids[i][j].DatasetId
                                        ] = {
                                            Name:
                                                dataGrids[i][j].DisplayName ||
                                                dataGrids[i][j].DatasetAlias,
                                            DateFilterColumn:
                                                dataGrids[i][j]
                                                    .DateFilterColumn,
                                        };
                                    }
                                }
                            }

                            resolve('resolved');
                            this.$store.commit(
                                'gridFilters/setGroupedTables',
                                groupedTables
                            );
                        }
                    })
                    .catch(() => {
                        resolve('rejected');
                    });
            });
        },
        dataSetRowSelected(args) {
            this.datasetId = args.data.DatasetId;
            this.datasetName = args.data.DatasetName;
            this.datasetAlias = args.data.name;
            this.selectedDatasets.push({ name: this.datasetAlias });
            const selectedDatasetRow = args.data;
            this.$emit('addToDataGrids', { selectedDatasetRow });

            this.addSelectedDatasets({
                projectId: this.projectIds,
                datasetId: this.datasetId,
            });
        },
        addRetrievedDataset(datasetId) {
            const matchingGrid = this.dataGrids.filter(
                (grid) => datasetId == grid.DatasetId
            );
            this.datasetId = datasetId;

            this.$emit('addToDataGrids', {
                selectedDatasetRow: matchingGrid[0],
            });
            this.addSelectedDatasets({
                projectId: this.projectIds,
                datasetId: this.datasetId,
            });
        },
        processSelectedDatasets() {
            const savedSelectedDatasets = this.getSelectedDatasets(
                this.projectIds
            );

            if (savedSelectedDatasets.length !== 0) {
                savedSelectedDatasets.forEach((selectedDataset) => {
                    const matchingDatasets = this.dataGrids.filter(
                        (grid) => selectedDataset == grid.DatasetId
                    );
                    if (matchingDatasets.length !== 0) {
                        matchingDatasets.forEach((matchingGrid) => {
                            this.$emit('addToDataGrids', {
                                selectedDatasetRow: matchingGrid,
                            });
                        });
                    }
                });
            } else {
                this.$emit('openPicker');
            }
        },
        resetDatagrid() {
            let grid = document.getElementById('PagedDataGrid');
            if (grid) {
                grid.innerHTML = '';
            }
        },
    },

    computed: {
        ...mapGetters('projects', {
            ProjectID: 'ProjectID',
        }),
        ...mapGetters('gridFilters', {
            getSelectedDatasets: 'getSelectedDatasets',
        }),
    },
    watch: {
        async ProjectID(newProjectID, oldProjectID) {
            if (newProjectID != oldProjectID) {
                this.resetDatagrid();
                this.projectIds = newProjectID;

                this.selectedDatasets = [];
                this.dataGrids = [];
                await this.getToolsData(newProjectID);
                if (newProjectID == 0) {
                    this.$store.commit('oneMap/mutateCloseSidebars', true);
                } else if (
                    newProjectID !== 0 &&
                    this.getSelectedDatasets(newProjectID).length !== 0
                ) {
                    this.processSelectedDatasets();
                }
            }
        },
    },
};
</script>
<style scoped>
.search-container {
    position: relative;
    width: 100%;
    height: 100px;
}
.search-box-container {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.filter-container {
    position: relative;
    float: left;
    width: 27%;
    height: 100%;
}
.search-box {
    position: relative;
    width: 100%;
    height: 47px;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
}
.search-img {
    position: relative;
    float: left;
    margin-left: 16px;
    margin-top: 11.75px;
}
.search-input-container {
    position: relative;
    float: right;
    width: 90%;
    margin-top: 7px;
    border-left: 1px solid #d1d1d1;
}

.search-input {
    position: relative;
    float: right;
    width: 95%;
    border: none;
    color: #777777;
}
.datasets-container {
    border: solid 1px lightgrey;
}
.modal-container {
    padding: 0 10px 10px 10px;
}
</style>
